/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalOfficerAction =
  typeof InternalOfficerAction[keyof typeof InternalOfficerAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalOfficerAction = {
  SUBMIT: 'SUBMIT',
  CANCEL: 'CANCEL',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
} as const;
